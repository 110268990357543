/*
 Description:  Silubi Tracer App Button Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.1
 Domain:       scan.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Button Formatting
-- Wallet Button
-- Option Button
-- Cancel Button
-- Mini Button
- Responsive Formatting
*/

/* ----------------------------- Button Formatting ----------------------------- */

.button {margin: 40px 0px;}

.button a:hover {color: #dadada;}

.main-button, .loading-button, .wallet-button {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e212e;
  border-radius: 50%;
}

.loading-button {
  -webkit-animation: spin 4s ease 2s infinite;
  -moz-animation: spin 4s ease 2s infinite;
  animation: spin 4s ease 2s infinite;
}
@-moz-keyframes spin {
  80% { -moz-transform: rotate(3600deg); }
  100% { -moz-transform: rotate(3600deg); }
}
@-webkit-keyframes spin {
  80% { -webkit-transform: rotate(3600deg); }
  100% { -webkit-transform: rotate(3600deg); }
}
@keyframes spin {
  80% { -webkit-transform: rotate(3600deg); transform:rotate(3600deg); }
  100% { -webkit-transform: rotate(3600deg); transform:rotate(3600deg); }
}

.main-button:hover {cursor: pointer;}

.main-button img {height: 65px;}
.main-button:hover img {height: 77px; transition: height 0.2s ease;}

.main-button .material-icons {font-size: 60px;}
.main-button:hover span {color: #fff; font-size: 68px; transition: font-size 0.2s ease;}

.main-button .button-data {font-size: 35px; font-weight: 500; margin: 0px 0px 5px 0px;}

.ring {
  width: 130px;
  height: 130px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #6ea1ab;
  border-radius: 50%;
}

.main-button-title {
  margin: 10px;
  font-weight: normal;
  font-size: large;
  display: block;
}

.buttonSeparator {
  width: 15px;
  display: inline-block;
}

/* -------------- Wallet Button Formatting -------------- */

.wallet-button img {height: 65px;}
.wallet-button .material-icons {font-size: 60px;}
.wallet-button .material-icons-round {font-size: 60px;}
.wallet-button .button-data {font-size: 35px; font-weight: 500; margin: 0px 0px 5px 0px;}


/* -------------- Option Button Formatting -------------- */

.option-button {
  color: #EBECEC;
  margin: 10px auto;
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #1e212e;
  border-radius: 10px;
  cursor: pointer;
}

.option-button:hover {font-size: 105%; transition: font-size 0.2s ease;}

.option-button-ring {
  padding: 8px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #6ea1ab;
  border-radius: 7px;
  font-weight: 500;
}


/* -------------- Cancel Button Formatting -------------- */

.cancel-button {
  color: #5b5b5b;
  margin: 10px auto;
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #dadada;
  border-radius: 10px;
  cursor: pointer;
}

.cancel-button-ring {
  padding: 8px 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #fff;
  border-radius: 7px;
  font-weight: 500;
}


/* -------------- Mini Button Formatting -------------- */

.mini-button {
  width: 40px;
  height: 40px;
  margin: -40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e212e;
  border-radius: 50%;
}

.mini-ring {
  width: 28px;
  height: 28px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #6ea1ab;
  border-radius: 50%;
}

.mini-button:hover {cursor: pointer;}

.mini-button .material-icons {font-size: 20px;}
.mini-button .material-icons-round {font-size: 20px;}
.mini-button:hover span {color: #fff; font-size: 24px; transition: font-size 0.2s ease;}


/* ----------------------------- Responsive Formatting ----------------------------- */

@media (max-width: 320px) {
  .main-button, .loading-button, .wallet-button {width: 120px; height: 120px;}
  .ring {width: 106px; height: 106px; border: solid 2px #6ea1ab;}

  .main-button .material-icons {font-size: 50px;}
  .main-button:hover span {color: #fff; font-size: 58px; transition: font-size 0.2s ease;}
}
