/*
 Description:  Silubi Tracer App Error Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.1
 Domain:       scan.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Error Detailing Formatting
- Error Modal Messages
- Error Section Formatting
- Responsive Formatting
*/
/* ----------------------------- Error Detailing Formatting ----------------------------- */

.error-ring {
  width: 130px;
  height: 130px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px firebrick;
  border-radius: 50%;
}

.warning-ring {
  width: 130px;
  height: 130px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px burlywood;
  border-radius: 50%;

  -webkit-animation: rock 5s ease 2s infinite;
  -moz-animation: rock 5s ease 2s infinite;
  animation: rock 5s ease 2s infinite;
}

@-moz-keyframes rock {
0% { -moz-transform: rotate(0deg); }
10% { -moz-transform: rotate(-5deg); }
20% { -moz-transform: rotate(5deg); }
30% { -moz-transform: rotate(0deg); }
100% { -moz-transform: rotate(0deg); }
}
@-webkit-keyframes rock {
0% { -webkit-transform: rotate(0deg); }
10% { -webkit-transform: rotate(-5deg); }
20% { -webkit-transform: rotate(5deg); }
30% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(0deg); }
}
@keyframes rock {
0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
10% { -webkit-transform: rotate(-5deg); transform:rotate(-5deg); }
20% { -webkit-transform: rotate(5deg); transform:rotate(5deg); }
30% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
}


/* ----------------------------- Error Modal Messages ----------------------------- */

.finding-location {
  min-width: 150px;
  color: burlywood;
}

.finding-location .material-icons-round {
  font-size: 40px;
  -webkit-animation: loc_spin 4s ease 2s infinite;
  -moz-animation: loc_spin45s ease 2s infinite;
  animation: loc_spin 4s ease 2s infinite;
}
.finding-location .material-icons {
  font-size: 40px;
  -webkit-animation: loc_spin 4s ease 0s infinite;
  -moz-animation: loc_spin45s ease 0s infinite;
  animation: loc_spin 4s ease 0s infinite;
}

@-moz-keyframes loc_spin {
  0% { -moz-transform: scaleX(1); }
  50% { -moz-transform: scaleX(-1); }
  }
  @-webkit-keyframes loc_spin {
  0% { -webkit-transform: scaleX(1); }
  50% { -webkit-transform: scaleX(-1); }
  }
  @keyframes loc_spin {
  0% { -webkit-transform: scaleX(1); transform: scaleX(1); }
  50% { -webkit-transform: scaleX(-1); transform: scaleX(-1); }
  }


/* ----------------------------- Error Section Formatting ----------------------------- */

div.error-footer {
    width: 100%;
    min-height: 20px;
    color: #adb7be;
    background-image: linear-gradient(#fff, #dadada);
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 3;
    border: 0px;
    margin: 0px;
    padding: 0px;
    flex-grow: 1;
  }
  
  div.error-footer a {color: #6ea1ab;}
  div.error-footer a:hover {color: #1c395a;}


  /* ----------------------------- Responsive Formatting ----------------------------- */

@media (max-width: 320px) {
  .error-ring {width: 105px; height: 105px; border: solid 2px firebrick;}
  .warning-ring {width: 105px; height: 105px; border: solid 2px burlywood;}
}