/*
 Description:  Silubi Tracer App Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.1
 Domain:       scan.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Global Formatting
- Page Section Formatting
- Scanning Formatting
- Result Section Formatting
-- Map Visualisation
-- Timeline Visualisation
-- Inventory Visualisation
- Responsive Formatting
*/

/* ----------------------------- Global Formatting ----------------------------- */
.App {
  height: 100px;
  color: #fff;
  font-family: "Segoe UI", "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  text-decoration: none;
  font-weight: normal;
  border: 0px;
  margin: 0 auto;
}

.App-body {
  background-color: #2a3c53;
  background-image: linear-gradient(174deg, #1e212e 20%, #1c395a, #0d6d8a, #6ea1ab, #93b5b3); /* Tim: Silubi Blue gradient */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

h1 {}
h2 {}
h3 {}
h4 {}
h5 {}

p {
  margin: 5px;
  line-height: 1.25;
  word-wrap: break-word;
}
p.product {
  font-size: larger;
  font-weight: 600;
  line-height: 0.90;
  margin: 5px 5px 15px 5px;
}
p.location {
  font-size: medium;
}
p.location-detail {
  color: #6ea1ab;
  font-size: small;
  line-height: 0.75;
}

a {
  color: #ffffff;
  font-family: "Segoe UI", "Open Sans", Verdana, Arial, Helvetica, sans-serif;
  text-decoration: none;
  border: 0px;
  cursor: pointer;
}
a:hover {
  color: #0d6d8a;
}

.App-link {
  color: #748892;
  display: none;
}

img.silubi-logo {
  width: 150px;
  margin: 50px 0px 0px 15px;
}

.blue-text {
  color: #1e212e;
  font-weight: bold;
}

.grey-text {color: #adb7be;}
.orange-text {color: coral;}
.teal-text {color: #0d6d8a;}


/* ----------------------------- Page Section Formatting ----------------------------- */

.header {
  width: 100%;
  height: 140px;
  text-align: center;
  font-size: 14px;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.content {
  width: 100%;
  text-align: center;
  justify-content: center;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.messaging {
  width: calc(100% - 40px);
  text-align: center;
  border: 0px;
  margin: 0px auto 30px auto;
  padding: 0px;
  font-size: large;
}

.button-area {
  height: 350px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  background-image: url(../images/supplychain-ilu.svg);
  background-size: auto;
  background-repeat: repeat-x;
  background-position: center bottom;
  border: 0px;
  margin: 0 auto;
  padding: 0px;
}

.scanner-area {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background-image: url(../images/supplychain-ilu.svg);
  background-size: auto;
  background-repeat: repeat-x;
  background-position: center bottom;
  border: 0px;
  margin: 0 auto;
  padding: 0px;
}


/* ----------------------------- Scanning Formatting ----------------------------- */

.scanRef {
  max-width: 90%;
  margin: 0px 2px 0px 0px;
  font-family: monospace;
  font-size: medium;
  word-break: break-all;
}

.scnnedItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
}


/* ----------------------------- Result Section Formatting ----------------------------- */

div.price {
  font-weight: bold;
  font-size: large;
  background-color: rgb(147, 181, 180, 0.3);
  padding: 5px 0px;
}

div.result-vis {
  min-height: calc(100vh - 580px);
  color: #5b5b5b;
  background-color: #fff;
  background-image: linear-gradient(#fff, #EBECEC);
  border: 0px;
  border-radius: 0px;
  margin: 0 auto;
  padding: 5px 0px 20px 0px;
}

div.result-footer {
  width: 100%;
  min-height: 25px;
  color: #adb7be;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.5px;
  border: 0px;
  margin: 0px;
  padding: 5px 0px 0px 0px;
}


/* --------------- Map Visualisation (see mapbox-gl.css) --------------- */

div.map-vis {
  max-width: 540px;
  margin: 20px auto 0px auto;
}

div.map-vis-error {
  display: none;
}


/* --------------- Timeline Visualisation --------------- */

div.timeline-vis {
  width: 500px;
  margin: 10px auto 0px auto;
  padding: 0px 0px 40px 0px;
  text-align: left;
  display: grid;
  grid: auto auto / 25px;
}

div.timeline-start {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-top.svg);
  background-repeat: no-repeat;
  background-position: center 27px;
  cursor: pointer;
}

div.timeline-node  {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-mid.svg);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

div.timeline-stop  {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-bot.svg);
  background-repeat: no-repeat;
  background-position: center -285px;
  cursor: pointer;
}

div.timeline-step {
  grid-column: 2 / 3;
  margin: 10px;
}

p.timeline-agent {
  margin: 2px;
  font-weight: bold;
  cursor: pointer;
}

p.timeline-data {
  color: #748892;
  margin: 2px;
  font-size: medium;
}

div.timeline-warn  {
  width: 20px;
  grid-column: 1 / 2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/timeline-gfx-warn.svg);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

p.timeline-agent-warn {
  color: #adb7be;
  margin: 2px;
  font-weight: normal;
  cursor: pointer;
}

p.timeline-data-warn {
  color: #6ea1ab;
  margin: 2px;
  font-size: medium;
}


/* --------------- Inventory Visualisation --------------- */

div.inventory-vis {
  max-width: 540px;
  margin: 10px auto 0px auto;
  padding: 0px 0px 12px 0px;
}

.inventoryTable {
  width: 90%;
  margin: 0px auto 12px auto;
  border-spacing: 0px;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: rgb(0, 0, 0, 0.05);
}

td:nth-child(even) {
  background-color: rgb(0, 0, 0, 0.025);
}

.inventoryTableHeader {
  text-align: left;
}

.inventoryTableHead {
  color: #fff;
  padding: 4px 8px 8px 8px;
  border-bottom: 5px solid #fff;
  background-color: #1c395a;
}

.inventoryTableHeadIcon {
  color: #fff;
  padding: 0px 4px;
  border-bottom: 5px solid #fff;
  background-color: #1c395a;
  text-align: center;
}
.inventoryTableHeadIcon .material-icons-round {font-size: 18px; padding: 0px; display: flex; align-items: center; justify-content: center;}
.inventoryTableHeadIcon .material-icons {font-size: 18px; padding: 0px; display: flex; align-items: center; justify-content: center;}

.inventoryTableRow {}

.inventoryTableData {
  padding: 4px 8px;
  vertical-align: middle;
}
.inventoryTableData .material-icons-round {font-size: 20px;}
.inventoryTableData .material-icons {font-size: 20px;}

.inventoryTableData a {
  color: #6ea1ab;
  font-weight: 500;
}

.inventoryTableLotMarker {border-bottom: 8px solid #fff;}
.inventoryTableLotMarkerData {text-align: left;}

.inventoryCloser {
  color: firebrick;
  padding: 3px 0px 0px 0px;
  font-size: small;
}
.inventoryCloser .material-icons-round {font-size: 20px; cursor: pointer;}
.inventoryCloser .material-icons {font-size: 20px; cursor: pointer;}

img.closeIcon {
  width: 16px;
  cursor: pointer;
}

.inventoryTableDataIcon {
  padding: 3px 0px 0px 0px;
  font-size: small;
}
.inventoryTableDataIcon .material-icons-round {font-size: 20px;}
.inventoryTableDataIcon .material-icons {font-size: 20px;}


/* ----------------------------- Responsive Formatting ----------------------------- */

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  div.timeline-vis {width: calc(100% - 200px);}
  div.map-vis {width: calc(100% - 200px);}
}

@media (max-width: 480px) {
  div.result-vis {border-radius: 0px 0px 20px 20px;}
  div.timeline-vis {width: calc(100% - 80px);}
  div.map-vis {width: calc(100% - 60px);}
  .inventoryTable {font-size: small;}

  .scanRef {
    padding: 0px 3px 5px 3px;
    text-align: left;
    border-bottom: 1px solid #adb7be;
  }
}

@media (max-width: 320px) {
  .grey-text {color: #adb7be; font-size: small;}

  div.timeline-vis {width: calc(100% - 40px);}
  div.map-vis {width: calc(100% - 40px);}

  .inventoryTable {width: 95%; font-size: 12px;}
}
