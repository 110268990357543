/*
 Description:  Silubi Tracer App Menu Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.1
 Domain:       scan.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Menu Panel Formatting
*/

/* ----------------------------- Menu Panel Formatting ----------------------------- */

.accountIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.7;
  cursor: pointer;
}

.accountImg {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 1;
  cursor: pointer;
}

.scannerIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0.7;
  cursor: pointer;
}

.menu {
  position: relative;
  overflow: hidden;
}

.menuObscurer {
  width: 100%;
  min-height: 100%;
  position: fixed;
  display: block;
  background-color: rgb(0, 0, 0, 0.5);
  transition: 600ms;
}

.menuPanel {
  width: 80%;
  min-height: 100%;
  color: #5b5b5b;
  padding: 15px;
  background-color: #dadada;
  background-image: linear-gradient(174deg, #EBECEC 30%, #fff 70%, #dadada 100%);
  position: fixed;
  z-index: 100;
  transition: 400ms;
}

.menuHeader {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 0px 5px;
  margin: 0px 0px 25px 0px;
  text-align: right;
  align-items: center;
}

.menuUsername {grid-row: 1 / 2; grid-column: 1 / 2;}
.menuEmail {grid-row: 2 / 3; grid-column: 1 / 2; font-size: 12px; color: #6ea1ab; margin: -3px 0px 0px 0px;}
.menuAccountImg {grid-row: 1 / 3; grid-column: 2 / 3;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin: 0px 0px 0px 3px;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}
.menuHeader .material-icons {grid-row: 1 / 3; grid-column: 2 / 3; font-size: 32px; cursor: pointer;}
.menuHeader .material-icons-round {grid-row: 1 / 3; grid-column: 2 / 3; font-size: 32px; cursor: pointer;}

.menuContent {
  text-align: center;
}

.menuAccountDetails {margin: 0px;}

.menuFooter {
  font-size: x-small;
  position: absolute;
  bottom: 40px;
  right: 15px;
}

.menuSeparator {
  height: 2px;
  margin: 25px 0px;
  background-color: #adb7be;
}

.menuBoldText {font-weight: bold;}
.menuSmallText {font-size: small;}
.menuLargeText {font-size: 20px;}

.menuLink {
  color: #6ea1ab;
  text-decoration: underline;
}
.menuLink:hover {
  color: #0d6d8a !important;
}