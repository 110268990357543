/*
 Description:  Silubi Tracer App Form Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.0
 Domain:       scan.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Form Formatting
- Responsive Formatting
*/

/* ----------------------------- Form Formatting ----------------------------- */

  .manualCodeForm {
    display: flex;
    align-items: center;
  }

  .manualCodeForm input {
    margin: 0px 4px 0px 0px;
    padding: 10px 8px;
    border: 1px solid #adb7be;
    border-radius: 5px;
  }

  .manualCodeForm button {
    color: #ffffff;
    padding: 4px;
    font-family: "Segoe UI", "Open Sans", Verdana, Arial, Helvetica, sans-serif;
    font-weight: 500;
    border: 0px;
    border-radius: 10px;
    background-color: #1e212e;
    cursor: pointer;
  }

  .manualCodeForm button .option-button-ring {width: 50px; height: 14px; padding: 6px 10px;}
  .manualCodeForm button .option-button-ring:hover {font-size: 108%; transition: font-size 0.2s ease;}

  ::-webkit-input-placeholder {color: #dddddd;} /* Edge */
  :-ms-input-placeholder {color: #dddddd;} /* Internet Explorer */
  ::placeholder {color: #dddddd;}


  
  /* ----------------------------- Responsive Formatting ----------------------------- */

  @media (max-width: 480px) {

  }

  @media (max-width: 320px) {
    
  }