/*
 Description:  Silubi Tracer App Modal Style
 Author:       WRT Dev Team (T Disney, M Babenko)
 Author URI:   https://www.silubi.com/
 Version:      1.1
 Domain:       scan.silubi.io
*/

/* ----------------------------- Style Sheet ----------------------------- */
/* INDEX:
- Modal Formatting
- QR Modal
- Responsive Formatting
*/

/* ----------------------------- Modal Formatting ----------------------------- */

  .modalObscurer {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    background-color: rgb(0, 0, 0, 0.6);
  }

  .modal {
    /* width: 80%; */
    max-width: 450px;
    color: #5b5b5b;
    margin: 0px 10px;
    word-wrap: break-word;
    border: 2px solid #dadada;
    border-radius: 20px;
    background-color: #fff;
    transition: 300ms;
  }

  .modalItemInfo {
    color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 20px 20px 0px 0px;
    background-color: #2a3c53;
    background-image: linear-gradient(177deg, #1e212e 20%, #1c395a, #0d6d8a);
  }
  .modalItemInfo-title {margin: 0px 0px 5px 0px; font-size: large; font-weight: 500;}
  .modalItemInfo-id {color: #dadada; font-size: x-small;}
  .modalItemInfo-price {margin: 15px 0px 0px 0px; font-size: x-large; font-weight: 500;}

  .modalContent {
    padding: 12px;
    text-align: center;
  }
  .modalContent-section {margin: 0px 0px 20px 0px;}
  .modalContent-title {color:#1c395a; font-size: large; font-weight: 500;}
  .modalContent-ID {font-size: small; font-weight: normal;}
  .modalContent-ID .MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root {width: 10px !important; height: 10px !important; margin: 0px 5px 0px 0px;}
  .modalContent-info {
    margin: -3px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalContent-info .material-icons {margin: 3px 7px 0px 7px;}
  .modalContent-info .MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root {width: 15px !important; height: 15px !important; margin: 0px 10px 0px 0px;}

  .MuiFormControlLabel-root {margin: 0px !important;}
  .MuiFormControlLabel-root .MuiButtonBase-root, .MuiCheckbox-root {padding: 4px !important;}
  .MuiInputBase-root .MuiSelect-select, .MuiInputBase-input, .MuiOutlinedInput-input {padding: 5px 32px 5px 10px !important;}
  .MuiOutlinedInput-root .MuiSelect-select, .MuiInputBase-input, .MuiOutlinedInput-input {padding: 5px 32px 5px 10px !important;}

  .modalForm {
    padding: 8px 0px 12px 0px;
    border-bottom: 1px solid #dadada;
  }

  .modalCloser {
    color: firebrick;
    display: inline-block;
    position: relative;
    top: 8px;
    right: 8px;
    float: right;
    cursor: pointer;
    z-index: 5000;
  }

  .modal-link {
    color: #6ea1ab;
    text-decoration: underline;
  }


  /* ----------------------------- QR Modal ----------------------------- */

  .qrLogo img {
    width: 160px;
    margin: 10px 0px 0px 30px;
  }

  .qrCode {
    margin: 0 auto;
    padding: 20px 15px 10px 15px;
    text-align: center;
  }

  .qrValue {
    color: #adb7be;
    /* font-size: larger; */
  }


  /* ----------------------------- Responsive Formatting ----------------------------- */

  @media (max-width: 480px) {
    .modal {max-width: 90%; padding: 0px; border-radius: 10px;}
    .qrCode {padding: 20px 0px 10px 0px;}

    .modalItemInfo {border-radius: 10px 10px 0px 0px;}
  }

  @media (max-width: 320px) {
    
  }